import TORUS from "./namespace";

import {
  CSS_SET,
  GROUP_ELEMENTS,
  optimizeAttribute,
  initClass,
  getIterableElement,
  getCounting,
} from "./util";

let mut;

TORUS.Group = class {
  constructor(element) {
    /** this element */
    this.element = element;

    if (!this.element.dataset.torGroup) {
      return;
    }

    /** Optimize and replace original [data-tor] attribute */
    this.element.dataset.torGroup = optimizeAttribute(this.element.dataset.torGroup);

    /** Replace all ` ` spaces in (<value>) definition and split into an array */
    this.dataset = this.element.dataset.torGroup.replace(/\((.*?)\)+/g, match => match.replace(/ +/g, "░"));

    /** Create store objects */
    this.attributes = this.attributes || {};
    this.targets = [];

    this._sortAttributes();
    this._assignAttributes();
    TORUS.Main.init(this.targets);
  }

  _sortAttributes() {
    let group = this.dataset.match(/(.*?);(?![^()]*\))/g);

    if (!group) {
      console.error("Missing semicolon at the end of " + this.dataset);
      return;
    }

    for (const item of group) {
      let target = /^(.*?)=>/.exec(item)[1];
      let attributes = /=>(.*?)$/.exec(item)[1].replace(/;$/, "").replace(/\s+(?=[^[\]]*\])/g, "░");

      this.attributes[target] = this.attributes[target] || {};

      for (const [i, attribute] of Object.entries(attributes.split(" "))) {
        this.attributes[target][i] = attribute;
      }
    }
  }

  _assignAttributes() {
    for (const [targets, attributes] of Object.entries(this.attributes)) {
      let count = 0;
      let allTargets = this.element.querySelectorAll(targets);

      for (const target of allTargets) {
        let tempAttributes = [];

        for (let attribute of Object.values(attributes)) {
          attribute = attribute.replace(/░+/g, " ");

          /** Has counting */
          if (/\/(.*?)\//.test(attribute)) {
            tempAttributes.push(this._counting(count, attribute));
          } else {
            tempAttributes.push(attribute);
          }
        }
        count += 1;

        if (target.dataset.tor) {
          target.dataset.tor = target.dataset.tor + " " + tempAttributes.join(" ");
        } else {
          target.dataset.tor = tempAttributes.join(" ");
        }
        GROUP_ELEMENTS.add(target);
        this.targets.push(target);
      }
    }
  }

  _counting(count, attribute) {
    let startFinal;
    let endFinal;

    const original = attribute.match(/\/(.*?)\/+/g);

    for (const match of original) {
      const split = match.split(";");
      let start = split[0];
      let end = split[1];

      /** Only <start> and no <end> value */
      if (!end) {
        end = start;
        start = null;
      }

      /** Check if <start> value has counting defined by `//` */
      if (/\/(.*?)\//.test(start)) {
        startFinal = getCounting(count, start);
      } else {
        startFinal = start;
      }

      /** Check if <end> value has counting defined by `//` */
      if (/\/(.*?)\//.test(end)) {
        endFinal = getCounting(count, end);
      } else {
        endFinal = end;
      }
      attribute = attribute.replace(match, `${startFinal ? startFinal + ";" : ""}${endFinal}`);
    }
    return attribute;
  }

  /**
   * ------------------------------------------------------------------------
   * Initialization
   * ------------------------------------------------------------------------
   */

  static init(elements) {
    elements = getIterableElement(elements || "[data-tor-group]");
    initClass({ name: "Group", elements: elements });
  }

}

export default TORUS.Group;