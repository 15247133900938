/**
 * ------------------------------------------------------------------------
 * Predefined CSS properties. Example: `[data-tor="bg-opacity()"]`
 * ------------------------------------------------------------------------
 */

const CSS_PREDEFINED_PROPERTIES = [
  "bg",
  "bg-opacity",
  "bg-lighten",
  "bg-darken",
  "bg-brightness",
  "block",
  "border",
  "border-opacity",
  "blur",
  "blur.to",
  "blur.from",
  "clip",
  "push.up",
  "push.down",
  "push.left",
  "push.right",
  "pull.up",
  "pull.left",
  "pull.right",
  "pull.down",
  "fade.in",
  "fade.out",
  "fade.to",
  "fade.from",
  "opacity",
  "reveal",
  "reveal.hide",
  "rotate.to",
  "rotate.from",
  "rotateX.to",
  "rotateX.from",
  "rotateY.to",
  "rotateY.from",
  "scale.to",
  "scale.from",
  "scaleX.to",
  "scaleX.from",
  "scaleY.to",
  "scaleY.from",
  "shadow",
  "svg-shadow",
  "shadow-offset.down",
  "shadow-offset.up",
  "shadow-offset.left",
  "shadow-offset.right",
  "shadow-intensity",
  "shadow-color",
  "skew.to",
  "skew.from",
  "skewX.to",
  "skewX.from",
  "skewY.to",
  "skewY.from",
  "text",
  "text-opacity",
  "delay",
  "duration",
  "top",
  "bottom",
  "up",
  "down",
  "left",
  "right",
  "shift.up",
  "shift.right",
  "shift.down",
  "shift.left",
  "originX",
  "originY",
  "originZ",
  "wait",
  "place.top",
  "place.right",
  "place.bottom",
  "place.left",
];

/**
 * ------------------------------------------------------------------------
 * Differents
 * ------------------------------------------------------------------------
 */

const CSS_DIFFERENTS = {
  activeValue: {
    "block": "var(--tor-block);",
    "fade.in": "0;",
    "fade.out": "0;",
    "clip": "var(--tor-clip-idle);",
    "block": "var(--tor-block-idle);",
    "reveal": "var(--tor-reveal-idle);",
    "reveal.hide": "var(--tor-reveal-idle);",
  },
  additionalRules: {
    "block": "--tor-block-scale: var(--tor-block-scale-idle); --tor-clip-delay: calc(var(--tor-duration-all) + var(--tor-delay-all, 0ms)); --tor-block-delay: var(--tor-delay-all, 0ms); --tor-block: var(--tor-block-idle);",
    // "block": "--tor-block-scale: var(--tor-block-scale-idle);",
    "reveal": "--tor-translateX: var(--tor-translateX-idle); --tor-translateY: var(--tor-translateY-idle);",
    "reveal.hide": "--tor-translateX: var(--tor-translateX-idle); --tor-translateY: var(--tor-translateY-idle);",
  },
  alias: {
    "blur*": "blur",
    "push*": "push-pull",
    "pull*": "push-pull",
    "shadow-offset*": "shadow-offset",
  },
  calc: {
    "push.up": -1,
    "push.left": -1,
    "pull.down": -1,
    "pull.right": -1,
    "shadow-offset.up": -1,
    "shadow-offset.left": -1,
    "shift.up": -1,
    "shift.left": -1,
  },
  propertyAlias: {
    "bg": "background-color",
    "bg-lighten": "--tor-bg-lightness",
    "bg-darken": "--tor-bg-lightness",
    "bg-brightness": "--tor-bg-lightness",
    "border": "border-color",
    "push.up": "--tor-translateY",
    "push.down": "--tor-translateY",
    "push.left": "--tor-translateX",
    "push.right": "--tor-translateX",
    "pull.up": "--tor-translateY",
    "pull.down": "--tor-translateY",
    "pull.left": "--tor-translateX",
    "pull.right": "--tor-translateX",
    "fade*": "--tor-opacity",
    "shadow": "box-shadow",
    "svg-shadow": "filter",
    "shadow-offset.down": "--tor-shadow-offsetY",
    "shadow-offset.up": "--tor-shadow-offsetY",
    "shadow-offset.left": "--tor-shadow-offsetX",
    "shadow-offset.right": "--tor-shadow-offsetX",
    "text": "color",
    "shift.up": "--tor-shiftY",
    "shift.down": "--tor-shiftY",
    "shift.left": "--tor-shiftX",
    "shift.right": "--tor-shiftX",
    "place.top": "--tor-top",
    "place.right": "--tor-right",
    "place.bottom": "--tor-bottom",
    "place.left": "--tor-left",
  },
  cssNot: [
    "blur.from",
    "block",
    "pull*",
    "clip",
    "fade.in",
    "reveal",
    "rotate.from",
    "rotateX.from",
    "rotateY.from",
    "scale.from",
    "scaleX.from",
    "scaleY.from",
    "skew.from",
    "skewX.from",
    "skewY.from",
  ],
  percentage: [
    "bg-opacity",
    "bg-brightness",
    "fade.to",
    "fade.from",
    "opacity",
    "scale*",
    "scaleX*",
    "scaleY*",
    "text-opacity",
  ],
}

/**
 * ------------------------------------------------------------------------
 * Create object from `CSS_PREDEFINED_PROPERTIES`, compare with `CSS_DIFFERENTS`
 * and return the new object
 * ------------------------------------------------------------------------
 */

const createPredefinedCSSObject = () => {
  const cssProperties = {};

  for (const propertyName of CSS_PREDEFINED_PROPERTIES) {
    cssProperties[propertyName] = {};

    /** If property has `.` dot, extract everything before. Example: `fade.out` */
    let exec = /^(.*?)\./.exec(propertyName);
    /** Assign extracted `exec` or default `property` */
    let item = exec ? exec[1] : propertyName;
    /** Add to object */
    cssProperties[propertyName].propertyAlias = `--tor-${item}`;

    addToPredefinedObject("propertyAlias", propertyName);
    addToPredefinedObject("activeValue", propertyName);
    addToPredefinedObject("additionalRules", propertyName);
    addToPredefinedObject("alias", propertyName);
    addToPredefinedObject("calc", propertyName);
    addToPredefinedObject("percentage", propertyName);
    addToPredefinedObject("cssNot", propertyName);
  }

  function addToPredefinedObject(object, propertyName) {
    let isArray = CSS_DIFFERENTS[object] instanceof Array ? true : false;

    for (const [key, value] of Object.entries(CSS_DIFFERENTS[object])) {
      let itemKey = isArray ? value : key;
      let itemValue = isArray ? true : value;

      /** If CSS_DIFFERENTS definition contains a `*` wildcard */
      if (/\*/g.test(itemKey)) {
        let matchKey = /^(.*?)\*/.exec(itemKey)[1];
        let matchName = /^(.*?)\./.exec(propertyName);

        if (matchName && matchKey === matchName[1]) {
          cssProperties[propertyName][object] = itemValue;
        }
      } else if (itemKey === propertyName) {
        cssProperties[propertyName][object] = itemValue;
      }
    }
  }

  return cssProperties;
}

export {
  createPredefinedCSSObject,
}