import TORUS from "./namespace";

import {
  createPredefinedCSSObject,
} from "./css";

/**
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 * WINDOW object
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 */

const WINDOW = {
  computedStyle: window.getComputedStyle(document.documentElement, null),
  height: window.innerHeight || document.documentElement.clientHeight,
  width: window.innerWidth || document.documentElement.clientWidth,
  resolution: {},
  resizing: false,
  scroll: {
    running: false,
    tick: 0,
    y: 0,
    x: 0,
  },
  mouse: {
    running: false,
    tick: 0,
    x: 0,
    y: 0,
  },
  idleCallback: window.requestIdleCallback ? true : false,
  isChrome: !!window.chrome,
  isUnsupportedSVG: !!window.chrome || /AppleWebKit/i.test(navigator.userAgent),
  isSafari: /Safari/i.test(navigator.userAgent) && !/Chrome/i.test(navigator.userAgent),
  mutationDone: false,
};

/**
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 * UTILITIES
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * Get current resolution
 * ------------------------------------------------------------------------
 */

const getCurrentResolution = () => {
  for (const [name, value] of Object.entries(CSS_BREAKPOINTS)) {
    if (WINDOW.width >= value.value) {
      WINDOW.resolution.name = name;
      WINDOW.resolution.value = value.value;
      break;
    }
  }
}

/**
 * ------------------------------------------------------------------------
 * Check if given resolution matches the current breakpoint
 * ------------------------------------------------------------------------
 */

const isCurrentResolution = (value) => {
  return WINDOW.resolution.value >= CSS_BREAKPOINTS[value].value;
}

/**
 * ------------------------------------------------------------------------
 * Create iterable elements from given parameter
 * ------------------------------------------------------------------------
 */

const getIterableElement = (elements) => {
  if (typeof elements === "string") {
    elements = [...document.querySelectorAll(elements)];
  }

  if (elements instanceof Node) {
    elements = [elements];
  }

  if (elements instanceof NodeList) {
    elements = [].slice.call(elements);
  }

  if (elements instanceof Set) {
    elements = [...elements];
  }

  if (!elements.length) {
    return false;
  }

  if (elements.length) {
    return elements;
  }

  else {
    return false;
  }
};

/**
 * ------------------------------------------------------------------------
 * Initialize Class
 * ------------------------------------------------------------------------
 */
const initClass = (params) => {

  if (!params.elements) return;

  async function loop() {
    let promise;
    for (const i in params.elements) {
      let element = params.elements[i];

      if (!element.TORUS) {
        element.TORUS = element.TORUS || {};
      }
      if (!element.TORUS[params.name]) {
        element.TORUS[params.name] = new TORUS[params.name](element, params.options);
        promise = await element.TORUS[params.name];
      } else {
        element.TORUS[params.name].refresh && element.TORUS[params.name].refresh();
        promise = await element.TORUS[params.name].refresh;
      }
    }

    return promise;
  }

  loop();
};


/**
 * ------------------------------------------------------------------------
 * Replace all with regex
 * ------------------------------------------------------------------------
 */

String.prototype.replaceAll = function (value) {
  let replacedString = this;
  for (let x in value) {
    replacedString = replacedString.replace(new RegExp(x, "g"), value[x]);
  }
  return replacedString;
};

/**
 * ------------------------------------------------------------------------
 * Optimize given attributes
 * ------------------------------------------------------------------------
 */

const optimizeAttribute = (attribute, shortcuts) => {
  if (!attribute) {
    return "";
  }

  /**
   * Find `@parallax` and `@tilt` shortcuts
   */

  if (shortcuts) {
    shortcuts = attribute.match(/(scroll|mouse|mouseX|mouseY|sensorX|sensorY)(.*?):(.*?)(@parallax|@tilt)\(.*?\)/g);

    if (shortcuts) {
      for (const shortcut of shortcuts) {
        let replace = /@(.*?)\)/.exec(shortcut)[0];
        let value = /\((.*?)\)/.exec(replace)[1];

        let transforms = {
          "@parallax": {
            name: "translate",
            method: "continuous",
            unit: "px",
            events: {
              mouseX: "X",
              mouseY: "Y",
              scroll: "Y",
            },
          },
          "@tilt": {
            name: "rotate",
            method: "self-continuous",
            unit: "deg",
            events: {
              mouseX: "Y",
              mouseY: "X",
            },
          },
        };

        for (const [type, values] of Object.entries(transforms)) {
          for (let [event, axis] of Object.entries(values.events)) {
            let test = new RegExp(`${event}(.*?)${type}`);

            /** Only one direction: `mouseX, mouseY, scroll` */
            if (test.test(shortcut)) {
              value = (type === "@tilt" && event === "mouseY") ? value : value.replace(/\d*/g, match => match && match.replace(match, `-${match}`));
              attribute = attribute.replace(replace, `@T=${values.name}${axis}(${value}${values.unit};0${values.unit},{method:${values.method}})`);
            }
            else { /** Two directions: `mouse` */
              test = new RegExp("^mouse:");

              if (test.test(shortcut)) {
                let arr = [];
                let re = new RegExp(`(mouse)(.*?)(${replace.replace(/\(/g, "\\(").replace(/\)/g, "\\)")})`, "g");
                let exec = re.exec(shortcut);

                for (const [event, axis] of Object.entries(values.events)) {
                  let symbol = (type === "@tilt" && event === "mouseY") ? "" : "-";
                  arr.push(exec[1].replace(exec[1], event) + exec[2] + `@T=${values.name}${axis}(${symbol}${value}${values.unit};0${values.unit}, {method:${values.method}})`)
                }

                let include = new RegExp(type);
                if (include.test(shortcut)) {
                  attribute = attribute.replace(shortcut, arr.join(" "));
                }
              }
            }

          }
        }
      }
    }
  }

  /**
   * Optimize
   */

  let optimized = removeSpaces (
    attribute
      .replace(/\s\s+/g, " ")
      .replace(/ $/g, ""),
    "\\[ | \\]|{ | }| { | : |: | :| ; |; | ;| ,|, | , |\\( | \\)|\\(\\( | \\(\\(| \\(\\( | \\)\\)| =>|=> | => | \\+| \\+ |\\+ | ~| ~ |~ | \\(")
    .replace(/\((.*?)\)+/g, match => match.replace(/ +/g, "░"))
    .replace(/\\/g, "")
    .replace(/@T=/g, "@transform=")
    .replace(/@F=/g, "@filter=")
    .trim();

  return optimized;
};

/**
 * ------------------------------------------------------------------------
 * Get resolution and value from
 * ------------------------------------------------------------------------
 */

const getResolution = (value) => {
  let resolution = null;
  value = value;

  if (/(xs|sm|md|lg|xl|xxl)::/g.test(value)) {
    let split = value.split("::");
    resolution = split[0];
    value = split[1];
  }

  return {
    resolution,
    value,
  };
};

/**
 * ------------------------------------------------------------------------
 * Get data from such as `value`, `unit`...
 * ------------------------------------------------------------------------
 */

const getValueData = (value) => {
  let unit = null;

  if (!/^@/.test(value) && /^(-|\+|\/\+|\/-|\/~|\.|.*?)\d/g.test(value) && !Number(value)) {
    let unitMatch = /(?!\d)(px|deg|%|cm|mm|in|pt|pc|em|ex|ch|rem|vw|vh|vmin|vmax|ms|s)+/g.exec(value.replace(/\/+/g, ""));
    if (unitMatch) {
      unit = unitMatch[1];
      value = value.replace(unit, "");
    }
  }

  value = parseValue(value);

  if (/^--/.test(value)) {
    value = `var(${value})`;
  }

  return {
    value,
    unit,
  };
};

/**
 * ------------------------------------------------------------------------
 * Helper function that returns CSS `var(--tor-*)` property
 * ------------------------------------------------------------------------
 */

const getCSSVariable = (params) => {
  let property = params.property;
  let value = params.value;
  let unit = params.unit ? params.unit : "";
  let wrap = params.wrap;

  let CSSVariable;
  let tempProperty;

  /** If value is from predefined ones */
  if (CSS_PREDEFINED_VARIABLES.includes(value)) {
    /** If there is alias for property. Example `push.up` has `push` alias */
    if (CSS_PROPERTIES[property] && CSS_PROPERTIES[property].alias) {
      tempProperty = CSS_PROPERTIES[property].alias ? CSS_PROPERTIES[property].alias : property;
    }
    else {
      tempProperty = property;
    }

    /** Check for `calc`. Some properties needs to be calculated in reversed direction. Example: `push.up` has `calc: -1` */
    if (CSS_PROPERTIES[property] && CSS_PROPERTIES[property].calc) {
      CSSVariable = `calc(var(--tor-${tempProperty}-${value}) * ${CSS_PROPERTIES[property].calc})`;
    }
    else {
      CSSVariable = `var(--tor-${tempProperty}-${value})`;
    }
  }
  /** It's a custom value */
  else {
    if (CSS_PROPERTIES[property] && CSS_PROPERTIES[property].calc) {
      CSSVariable = `${value * CSS_PROPERTIES[property].calc}${unit}`;
    }
    else {
      CSSVariable = wrap ? `${wrap}(${value}${unit})` : `${value}${unit}`;
    }
  }

  /** If has active value, that has to be added immediately. Example: `fade.in` has `activeValue = 0` (opacity: 0) */
  if (CSS_PROPERTIES[property] && CSS_PROPERTIES[property].activeValue) {
    CSSVariable = CSS_PROPERTIES[property].activeValue;
  }

  return CSSVariable;
}

/**
 * ------------------------------------------------------------------------
 * Expand cluster defined by `[]`
 * Example: active:[opacity(50%) bg(red)] -> active:opacity(50%) active:bg(red)
 * ------------------------------------------------------------------------
 */

const expandCluster = (attributes) => {
  let matches = attributes.match(/\b([^\s]+)\[(.*?)\]/g);

  if (matches) {
    /** Loop trough all matches */
    for (let match of matches) {
      let attributesArray = [];
      let original = match;

      /** Extract options defined by `{<options>}` */
      let options = /,{(?:.(?!,{))+}(?=\])/g.exec(match);

      if (options) {
        match = match.replace(options[0], "");
        options = /,{(.*?)}/.exec(options[0])[1];
      }

      /** Extract trigger defined by `trigger-name` and colon `:` */
      let trigger = /^(.*?)\:/.exec(match)[1];

      /** Extract everything between curly brackets `{}` and split by the space */
      let contents = /\[(.*?)\]+/g.exec(match)[1].split(" ");

      /** Combine every single content (attribute) with its trigger */
      for (let content of contents) {
        /** If has priority defined by `!` */
        if (/^!/.test(content)) {
          attributesArray.push(`!${trigger}:${getContent(options, content.replace("!", ""))}`);
        }
        else {
          attributesArray.push(`${trigger}:${getContent(options, content)}`);
        }
      }

      /** Replace original attribute cluster with separated attributes */
      attributes = attributes.replace(original, attributesArray.join(" "));
    }
  }

  function getContent(options, content) {
    if (options) {
      if (/}\)/.test(content)) {
        content = content.replace("})", `,${options}})`);
      } else {
        content = content.replace(")", `,{${options}})`);
      }
    }
    return content;
  }

  return attributes;
}

/**
 * ------------------------------------------------------------------------
 * Parse value. string/integer/float
 * ------------------------------------------------------------------------
 */

const parseValue = (value) => {
  /** Check if number is float */
  if (/(^(\d.*?)|^()|^(-\d.*?))\.(\d)+/g.test(value)) {
    value = parseFloat(/((-|\+|)[0-9]*[.])?[0-9]+/g.exec(value)[0]);
  }
  /** Number is integer */
  else if (/^[-+]?\d+$/.test(value)) {
    value = parseInt(/[+-]?(\d)+/g.exec(value)[0]);
  }
  /** Number is string (or contains number with non-number character) */
  else {
    value = value;
  }

  return value;
};

/**
 * ------------------------------------------------------------------------
 * Remove unnecessary spaces and unify them, remove tabs, etc
 * ------------------------------------------------------------------------
 */

const removeSpaces = (string, replacement) => {
  let oldString = string;
  let newString, re, replacedPattern;

  for (const pattern of replacement.split("|")) {
    replacedPattern = pattern.replace(/ /g, "");
    re = new RegExp(pattern, "g");
    newString = oldString.replace(re, replacedPattern);
    oldString = newString.replace(/\\+/g, "");
  }

  return newString;
}

/**
 * ------------------------------------------------------------------------
 * Insert generated CSS into <head> stylesheet
 * ------------------------------------------------------------------------
 */

const insertStylesheet = (css) => {
  STYLE.sheet.insertRule(css, STYLE.sheet.cssRules.length);
}

/**
 * ------------------------------------------------------------------------
 * Calculate percents based on mouse move
 * ------------------------------------------------------------------------
 */

/**
 *
 * @param {object} _this Object contains element bounds
 * @param {string} origin Origin for mouse position calculation
 * @returns {number}
 */

const getPercents = (_this, params) => {
  let percents = {};

  switch (params.event) {
    /**
     * Mouse
     */
    case "mouse": {
      switch (params.options.method) {
        case "middle": {
          percents = {
            x: 1 - Math.abs((WINDOW.width / 2 - WINDOW.mouse.x) / (WINDOW.width / 2)),
            y: (1 - Math.abs((WINDOW.height / 2 - WINDOW.mouse.y) / (WINDOW.height / 2))),
            all: (1 - Math.sqrt(Math.pow(WINDOW.width / 2 - WINDOW.mouse.x, 2) + Math.pow(WINDOW.height / 2 - WINDOW.mouse.y, 2)) / Math.sqrt(Math.pow(WINDOW.width / 2, 2) + Math.pow(WINDOW.height / 2, 2))),
          }
          break;
        }
        case "continuous": {
          percents = {
            x: (1 - (WINDOW.width / 2 - WINDOW.mouse.x) / (WINDOW.width / 2)),
            y: (1 - (WINDOW.height / 2 - WINDOW.mouse.y) / (WINDOW.height / 2)),
          }
          break;
        }
        case "self": {
          let _x = _this && 1 - Math.abs((WINDOW.mouse.x - _this.bounds.centerX) / _this.bounds.maxXSide);
          let _y = _this && 1 - Math.abs((WINDOW.mouse.y - _this.bounds.centerY) / _this.bounds.maxYSide);

          percents = {
            x: Math.min(1, Math.max(0, _x)),
            y: Math.min(1, Math.max(0, _y)),
            all: _this && (getMouseHoverPosition(_this, _this.bounds.centerX, _this.bounds.centerY))
          }
          break;
        }
        case "self-continuous": {
          percents = {
            x: _this && 1 + ((WINDOW.mouse.x - _this.bounds.centerX) / _this.bounds.maxXSide),
            y: _this && 1 + ((WINDOW.mouse.y - _this.bounds.centerY) / _this.bounds.maxYSide),
            // all:  _this && (getMouseHoverPosition(_this, _this.bounds.centerX/_this.bounds.maxXSide, _this.bounds.centerY/_this.bounds.maxYSide))
          }
          break;
        }
        case "parallax": {
          percents = {
            x: ((WINDOW.mouse.x - WINDOW.width / 2) / (WINDOW.width / 2)),
            y: ((WINDOW.mouse.y - WINDOW.height / 2) / (WINDOW.height / 2))
          }
          break;
        }
        case "start": {
          let _x = WINDOW.mouse.x / WINDOW.width;
          let _y = WINDOW.mouse.y / WINDOW.height;

          percents = {
            x: _x,
            y: _y,
            all: (_x + _y) / 2,
          }

          break;
        }

      }
      break;
    }
    /**
     * Scroll
     */
    case "scroll": {
      let start;
      let end;
      let x;
      let y;
      let shiftStart = 0;
      let shiftEnd = 0;

      let optionsEnd = params.options.end;
      let optionsStart = params.options.start;

      if (optionsEnd === "middle") {
        shiftEnd = _this.bounds.height / 2;
        optionsEnd = 50;
      }
      if (optionsStart === "shifted") {
        optionsStart = 0;
        shiftStart = _this.bounds.height / 2;
        shiftEnd = 0;
      }

      start = (WINDOW.height / 100) * optionsStart + shiftStart;
      end = ((WINDOW.height / 100) * (optionsEnd - optionsStart)) + shiftEnd;

      x = null;
      y = (WINDOW.height + WINDOW.scroll.y - _this.bounds.offsetTop - start) / end;

      // let usingOffsetAmount = (end || start) ? true : false;
      // // let usingScrollAmount = (afterScrolledStart || afterScrolledEnd) ? true : false;
      // let usingScrollAmount = false;

      // if (usingScrollAmount) {
      //   if (!afterScrolledStart) {
      //     afterEnd = afterScrolledEnd;
      //   } else {
      //     afterStart = afterScrolledEnd;
      //     afterEnd = afterScrolledStart;
      //   }

      //   if (afterScrolledStart && afterScrolledEnd) {
      //     afterScrollDifference = afterScrolledEnd - afterScrolledStart;
      //   }
      // }

      // let _x = null;
      // let _y = (-0.0001 + WINDOW.height - (_this.bounds.offsetTop - WINDOW.scroll.y + offsetA)) / (((WINDOW.height + (end === "middle" ? _this.bounds.height : 0)) / 100) * ((end === "middle" ? 49.99 : end) - offsetB));

      switch (params.options.method) {
        case "continuous": {
          percents = {
            x: x,
            y: y,
          }
          break;
        }

        case "regular": {
          // if (usingOffsetAmount) {
            percents = {
              x: x,
              y: Math.min(1, Math.max(0, y)),
            }
          // }
          // if (usingScrollAmount) {
          //   percents = {
          //     x: _x,
          //     y: (WINDOW.scroll.y - afterEnd) / (afterScrollDifference || (WINDOW.height / 2)),
          //   }
          // }
          break;
        }
      }
      break;
    }
  }

  return {
    x: Math.round(percents.x * 1000) / 1000,
    y: Math.round(percents.y * 1000) / 1000,
    all: Math.round(percents.all * 1000) / 1000,
  };
};

/**
 * ------------------------------------------------------------------------
 * Calculate the longest distance from element center to one of the screen corners
 * ------------------------------------------------------------------------
 */

const getMaxSide = (_this) => {
  let lt = Math.sqrt(Math.pow(_this.bounds.centerX, 2) + Math.pow(_this.bounds.centerY, 2));
  let lb = Math.sqrt(Math.pow(_this.bounds.centerX, 2) + Math.pow(WINDOW.height - _this.bounds.centerY, 2));
  let rt = Math.sqrt(Math.pow(WINDOW.width - _this.bounds.centerX, 2) + Math.pow(_this.bounds.centerY, 2));
  let rb = Math.sqrt((Math.pow(WINDOW.width - _this.bounds.centerX, 2) + Math.pow(WINDOW.height - _this.bounds.centerY, 2)));
  let ls = _this.bounds.centerX;
  let rs = WINDOW.width - _this.bounds.centerX;
  let ts = _this.bounds.centerY;
  let bs = WINDOW.height - _this.bounds.centerY;
  let corner = Math.max(...[lt, lb, rt, rb]);
  let xSide = Math.max(...[ls, rs]);
  let ySide = Math.max(...[ts, bs]);

  return { corner, xSide, ySide };
};

/**
 * ------------------------------------------------------------------------
 * Get the shortest distance from given centerX, centerY to mouse pointer
 * ------------------------------------------------------------------------
 */

const getMouseHoverPosition = (_this, centerX, centerY) => {
  return 1 - Math.abs(Math.sqrt(Math.pow(Math.abs(centerX - WINDOW.mouse.x), 2) + Math.pow(Math.abs(centerY - WINDOW.mouse.y), 2)) / _this.bounds.maxDiagonal);
};

/**
 * ------------------------------------------------------------------------
 * Get scroll values
 * ------------------------------------------------------------------------
 */

const getWindowScroll = () => {
  WINDOW.scroll.y = window.scrollY;
  WINDOW.scroll.x = window.scrollX;
}

/**
 * ------------------------------------------------------------------------
 * Run on browser idle
 * ------------------------------------------------------------------------
 */

const onIdle = (entry, svgRect, parentRect) => {
  let target = svgRect ? entry : entry.target;
  let rect = svgRect ? svgRect : entry.boundingClientRect;

  if (WINDOW.idleCallback) {
    requestIdleCallback(() => {
      bounds();
    });
  } else {
    requestAnimationFrame(() => {
      setTimeout(() => {
        bounds();
      }, 0);
    });
  }

  function bounds() {
    if (target.TORUS && target.TORUS.Main) {
      if (svgRect) {
        target.TORUS.Main.set.bounds(rect, parentRect);
      } else {
        target.TORUS.Main.set.bounds(rect);
      }
    }
  }
}

/**
 * ------------------------------------------------------------------------
 * Return counting value
 * ------------------------------------------------------------------------
 */

const getCounting = (count, value) => {
  value = value.replace(/\//g, "");

  /** Beginning value - defines the starting value of counting */
  let begin = /(.).*?(?=\+|-|~)/.exec(value);
  if (begin) {
    value = value.replace(begin[0], "");
    begin = getValueData(begin[0]).value;
  }

  let symbol = /\+|-|~/.exec(value);
  if (symbol) {
    symbol = symbol[0];
    value = value.replace(symbol, "");
  }

  let countValue = getValueData(value).value;
  let countUnit = getValueData(value).unit;

  switch (symbol) {
    case "+":
      count = count;
      break;

    case "-":
      count = -count;
      break;

    case "~":
      count = 1;
      countValue = (Math.round(Math.random() * (countValue - 0) + 0));
      break;
  }


  return `${begin + (count * countValue)}${countUnit ? countUnit : ""}`;
}

/**
 * ------------------------------------------------------------------------
 * Call given function in TORUS element
 * ------------------------------------------------------------------------
 */

const callFunction = (params) => {
  if (!params.elements) return;
  if (!params.elements.length) return;

  for (const element of getIterableElement(params.elements)) {
    if (element.TORUS && element.TORUS[params.object]) {
      element.TORUS[params.object][params.fn](params.argument);
    }
  }
};

/**
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 * GLOBALS
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * Generated predefined CSS variables
 * ------------------------------------------------------------------------
 */

const CSS_PREDEFINED_VARIABLES = WINDOW.computedStyle.getPropertyValue("--tor-predefined-values").trim().split(",");
// const CSS_PREDEFINED_VARIABLES = "blue,indigo,purple,pink,red,orange,yellow,green,teal,cyan,white,gray,gray-dark,navy,maroon,brown,magenta,lime,black,primary,secondary,success,info,warning,danger,light,dark,no,xs,sm,md,lg,xl,full,half,risen,pop,fastest,faster,fast,slow,slower,slowest".split(",");

/**
 * ------------------------------------------------------------------------
 * Breakpoints names with their resolutions
 * ------------------------------------------------------------------------
 */

const CSS_BREAKPOINTS = {}
const cssBreakpoints = WINDOW.computedStyle.getPropertyValue("--tor-resolutions").replace(/"| +/g, "").split(",");
// const cssBreakpoints = ["xxl:1400px", "xl:1200px", "lg:992px", "md:768px", "sm:576px", "all:0px"];
let cssBreakpointsLength = cssBreakpoints.length - 1;

for (const breakpoint of cssBreakpoints) {
  let split = breakpoint.split(":");
  let data = getValueData(split[1]);

  CSS_BREAKPOINTS[split[0]] = {}
  CSS_BREAKPOINTS[split[0]].value = data.value;
  CSS_BREAKPOINTS[split[0]].unit = data.unit;
  CSS_BREAKPOINTS[split[0]].id = cssBreakpointsLength--;
}

getCurrentResolution();

/**
 * ------------------------------------------------------------------------
 * Trigger aliases used to defined a CSS rule
 * ------------------------------------------------------------------------
 */

const CSS_TRIGGER_ALIAS = {
  inview: ".inview",
  active: ".active",
  show: ".show",
  hover: ":hover",
  focus: ":focus",
  "focus-within": ":focus-within",
}

/**
 * ------------------------------------------------------------------------
 * Property alias for default CSS rule definition
 * ------------------------------------------------------------------------
 */

const CSS_PROPERTIES = createPredefinedCSSObject();

/**
 * ------------------------------------------------------------------------
 * Create <style> element
 * ------------------------------------------------------------------------
 */

const STYLE = document.createElement("style");


/**
 * ------------------------------------------------------------------------
 * Individual attribute properties
 * ------------------------------------------------------------------------
 */

const ATTRIBUTE_SEGMENTS = {
  priority: {
    indexReplace: 0,
    indexValue: 0,
    regex: /^!(.*?)/,
  },
  trigger: {
    indexReplace: 0,
    indexValue: 0,
    regex: /^((?:.)(?:((?!::)+(?!{))))*?(?:\:)/,
  },
  resolution: {
    indexReplace: 0,
    indexValue: 2,
    regex: /^(<|=|)(xs|sm|md|lg|xl|xxl)(.*?)::/,
  },
  property: {
    indexReplace: 1,
    indexValue: 1,
    regex: /^(?:@|)(.*?)(\(|$)/,
  },
  values: {
    indexReplace: 0,
    indexValue: 1,
    regex: /^\((.*?)\)$/,
  },
}

/**
 * ------------------------------------------------------------------------
 * Properties object
 * ------------------------------------------------------------------------
 */

const createPropertiesObject = (_this, array, group) => {
  let temp = {};
  _this.attributes[group] = _this.attributes[group] || {};

  /**
   * Sort attributes by their <group> and <trigger>
   */

  for (const item of array) {
    let triggerGroup;
    let trigger = /^((?:.)(?:((?!::)+(?!{))))*?(?=\:)/g.exec(item);
    _this.attributes[group] = _this.attributes[group] || {};

    if (trigger) {
      trigger = trigger[0].replace("!", "");
      temp[group] = temp[group] || {};

      if (/^(mouse|scroll|sensor)/.test(trigger)) {
        triggerGroup = /^.*?(?=(X|Y))/.exec(trigger);
        triggerGroup = triggerGroup ? triggerGroup[0] : trigger;

        _this.attributes[group][triggerGroup] = _this.attributes[group][triggerGroup] || {}
        _this.attributes[group][triggerGroup][trigger] = _this.attributes[group][triggerGroup][trigger] || {}

        temp[group][triggerGroup] = temp[group][triggerGroup] || {};
        temp[group][triggerGroup][trigger] = temp[group][triggerGroup][trigger] || [];
        temp[group][triggerGroup][trigger].push(item);
      } else {
        _this.attributes[group][trigger] = _this.attributes[group][trigger] || {}

        temp[group][trigger] = temp[group][trigger] || [];
        temp[group][trigger].push(item);
      }
    } else {
      _this.attributes[group].idle = _this.attributes[group].idle || {}
      temp[group] = temp[group] || {};
      temp[group].idle = temp[group].idle || [];
      temp[group].idle.push(item);
    }
  }

  /**
   * Call the `createSegment` function in loop
   */

  for (const [group, object] of Object.entries(temp)) {
    for (const [trigger, array] of Object.entries(object)) {
      if (/mouse|scroll|sensor/.test(trigger)) {
        for (const [specificTrigger, item] of Object.entries(array)) {
          createSegment(_this, group, trigger, item, specificTrigger);
        }
      } else {
        createSegment(_this, group, trigger, array);
      }
    }
  }

  /**
   * Create segment
   */

  function createSegment(_this, group, trigger, array, specificTrigger) {
    for (const [i, dataAttribute] of Object.entries(array)) {
      let temp = dataAttribute;
      let attribute;

      if (specificTrigger) {
        attribute = _this.attributes[group][trigger][specificTrigger][i] = {};
      } else {
        attribute = _this.attributes[group][trigger][i] = {};
      }

      /** Original [data-attribute] */
      attribute.original = dataAttribute;

      /** Check for custom property defined by `@`. Example: `hover:@opacity(0; 0.5)` */
      if (/@/.test(temp)) {
        attribute.isCustom = true;
        temp = temp.replace("@", "");
      }

      /** Loop through all `ATTRIBUTE_SEGMENTS` (priority, trigger, property...) */
      for (let [segmentName, segmentValue] of Object.entries(ATTRIBUTE_SEGMENTS)) {
        let exec = segmentValue.regex.exec(temp);
        if (exec) {
          temp = temp.replace(exec[segmentValue.indexReplace], "");
          createSegmentObject(_this, attribute, exec, segmentName, segmentValue);
        } else {
          createSegmentObject(_this, attribute, exec, `${segmentName}:default`, segmentValue);
        }
      }
    }
  }
}

/**
 * ------------------------------------------------------------------------
 * Segments
 * ------------------------------------------------------------------------
 */

const createSegmentObject = (_this, attribute, exec, segmentName, segmentValue) => {
  switch (segmentName) {
    case "priority":
      attribute.priority = "important";
      break;

    case "trigger":
      attribute.trigger = {};
      let temp = exec[segmentValue.indexValue].replace(":", "");

      /** Check for trigger argument. @example: `hover(p):fade.in` */
      let argument = /\((.*?)\)/.exec(temp);

      if (argument) {
        attribute.trigger.name = temp.replace(argument[0], "");
        attribute.trigger.argument = argument[1];

        if (argument[1] === "p") {
          attribute.trigger.argument = "parent";
        }
        if (/^#/.test(argument[1])) {
          switch (attribute.trigger.name) {
            case "scroll":
              document.querySelector(argument[1]).addEventListener("scroll", ON_ELEMENT_SCROLL, { passive: true });
              break;

              default:
                TORUS.Parent.init(document.querySelector(argument[1]), { trigger: attribute.trigger.name });
              break;
          }
        }
      } else {
        attribute.trigger.name = temp;
      }

      attribute.trigger.alias = CSS_TRIGGER_ALIAS[attribute.trigger.name];

      /** Direction. @example: `mouseX:`. direction = "x" */
      let match = /X$|Y$/i.exec(attribute.trigger.name);
      if (match) {
        attribute.trigger.direction = match[0].toLowerCase();
      } else {
        attribute.trigger.direction = "all";
      }

      if (attribute.trigger.name === "scroll") {
        attribute.trigger.direction = "y";
      }
      break;

    case "resolution":
      attribute.resolution = exec[segmentValue.indexValue];
      break;

    case "property":
      attribute.property = {};
      attribute.property.name = exec[segmentValue.indexValue];

      if (/=/.test(attribute.property.name)) {
        let split = attribute.property.name.split("=");
        attribute.property.cssFunction = split[0];
        attribute.property.name = split[1];
      }

      if (/^bg$|^border$|^color$|^shadow$/.test(attribute.property.name)) {
        attribute.priority = true;
      }
      if (/^background-color$/.test(attribute.property.name)) {
        attribute.joinSymbol = ",";
      } else {
        attribute.joinSymbol = " ";
      }

      checkPredefined(_this, attribute);
      break;

    case "values":
      createValues(_this, attribute, exec, segmentValue);

      switch (attribute.property.name) {
        case "offset":
          _this.is.inviewOffset = attribute.values.all.end.value;
          break;
      }
      break;

    case "resolution:default":
      attribute.resolution = "all";
      break;
  }
}

/**
 * ------------------------------------------------------------------------
 * Check for predefined properties
 * ------------------------------------------------------------------------
 */

const checkPredefined = (_this, attribute) => {
  let predefined = CSS_PROPERTIES[attribute.property.name];

  if (predefined) {
    attribute.property.alias = predefined.propertyAlias || "";

    /** If defined, replace the original triggerAlias with `:not(<trigger>)` */
    if (predefined.cssNot && attribute.trigger) {
      attribute.trigger.alias = `:not(${CSS_TRIGGER_ALIAS[attribute.trigger.name]})`;
    }
  } else {
    /** Don't process the attribute, but only if it's not a custom one */
    if (!attribute.isCustom) {
      attribute.noCSSProcess = true;

      switch (attribute.original) {
        case "inview:revert":
          _this.is.inviewRevert = true;
          break;
      }
    }
  }
}

/**
 * ------------------------------------------------------------------------
 * Create <values> object
 * ------------------------------------------------------------------------
 */

const createValues = (_this, attribute, exec, segmentValue) => {
  let valueSplit;
  let optionList;
  let tempValue;
  let valueObject = {};

  attribute.values = {};
  attribute.values.all = attribute.values.all || {};

  if (/\.\.\./.test(attribute.original)) {
    attribute.values.multi = true;
    let cssFunction = /\((.*?)\(/.exec(attribute.original);

    if (cssFunction) {
      attribute.values.cssFunction = cssFunction[1];
    }
  }

  tempValue = exec[segmentValue.indexValue];

  // if (/'(.*?)'/.test(tempValue)) {
  //   let exec = /'(.*?)'/.exec(tempValue);
  //   tempValue = tempValue.replace(exec[0], exec[0].replace(/,+/g, "|").replace(/'+/g, ""))
  // }

  /**
   * <options>
   * If value has options defined by `{}`
   */

  attribute.options = {};

  /** Default options */
  if (attribute.trigger) {
    if (/mouse/.test(attribute.original)) {
      attribute.options.method = "middle";
    }
    if (/scroll(?!(.*?)class)/.test(attribute.original)) {
      attribute.options.start = 0;
      attribute.options.end = "middle";
      attribute.options.method = "regular";
    }
  }

  optionList = /(,{|^{)(.*?)}/.exec(tempValue);

  if (optionList) {
    for (const option of optionList[2].split(",")) {
      let split = option.split(":");
      let optionName = split[0];
      let optionValue = split[1];

      switch (optionName) {
        case "target":
          optionValue = optionValue.replace(/\|+/g, ",").replace(/░+/g, " ");
          attribute.options[optionName] = document.querySelectorAll(optionValue);
          break;

        default:
          if (/^--/.test(optionValue)) {
            attribute.options[optionName] = `var(${optionValue})`;
          } else {
            if (CSS_PREDEFINED_VARIABLES.includes(optionValue)) {
              attribute.options[optionName] = `var(--tor-${optionValue})`;
            } else {
              attribute.options[optionName] = /true|false/.test(optionValue) ? JSON.parse(optionValue) : optionValue;
            }
          }
          break;
      }
    }

    tempValue = tempValue.replace(optionList[0], "");
  }

  /** Check for <start> and <end> values */
  valueSplit = tempValue.split(";");
  valueObject.end = valueSplit[1];

  if (valueObject.end) {
    valueObject.start = checkMultiValues(valueSplit[0]);
    valueObject.end = checkMultiValues(valueSplit[1]);
  } else {
    valueObject.start = null;
    valueObject.end = valueSplit[0];
  }


  /**
   * <resolutions>
   * Value has resolutions defined by `::`. Example: `hover:scale.to(2 lg::3)`
   */

  for (const type of ["start", "end"]) {
    if (valueObject[type]) {
      if (/(xs|sm|md|lg|xl|xxl)::/g.test(valueObject[type])) {
        for (const value of valueObject[type].split("░")) {
          const GR = getResolution(value);
          let data = checkPercentage(getValueData(GR.value));

          if (GR.resolution) {
            attribute.values[GR.resolution] = attribute.values[GR.resolution] || {};
            addData(GR.resolution, type, data);
          }
          else {
            addData("all", type, data);
          }
        }
      } else {
        /** Value has no resolutions */
        addData("all", type, checkPercentage(getValueData(valueObject[type])) );
      }
    }
  }

  /**
   * Check if it's `class-actions` attribute
   */

  if (/:class./.test(attribute.original)) {
    if (!attribute.options.target) {
      attribute.options.target = _this.element;
    }
  }

  /** Helper function */

  function addData(resolution, type, data) {
    attribute.values[resolution][type] = {};
    attribute.values[resolution].original = tempValue;

    for (const [key, value] of Object.entries({ value: data.value, unit: data.unit })) {
      attribute.values[resolution][type][key] = (value || value === 0) ? value : null;
    }
  }

  /**
  * <percentage>
  * If value has `percentage` flag - the input value is in percents. Example `opacity(50%)` -> opacity: .5
  */

  function checkPercentage(params) {
    let value;
    let unit;

    if (CSS_PROPERTIES[attribute.property.name] && CSS_PROPERTIES[attribute.property.name].percentage) {
      value = params.unit === "%" ? params.value / 100 : params.value;
      unit = null;
    } else {
      value = params.value;
      unit = params.unit;
    }

    return {
      value,
      unit
    };
  }

  /**
   * Check for multi values defined by `...`
   * @param {string} original
   * @returns {object or string}
   */

  function checkMultiValues(original) {
    let temp = original;

    if (/\.\.\./.test(temp)) {
      temp = {};
      original = original.replace("...", "");
      original = attribute.values.cssFunction ? original.replace(attribute.values.cssFunction, "").replace(/\(|\)/g, "") : original;

      for (const [i, value] of Object.entries(original.split(",") ) ) {
        let GVD = getValueData(value);
        temp[i] = {};
        temp[i].value = GVD.value;
        temp[i].unit = GVD.unit;
      }
    }

    return temp;
  }
}

/**
 * ------------------------------------------------------------------------
 * Get values
 * ------------------------------------------------------------------------
 */

const getValuesForCurrentResolution = (attribute, percents, index) => {
  if (!attribute.values) {
    return {
      value: null,
      unit: null,
    };
  }

  let unit;
  let start = 0;
  let end;
  let value;

  for (let i = 0; i <= CSS_BREAKPOINTS[WINDOW.resolution.name].id; i++) {
    let breakpoints = Object.keys(CSS_BREAKPOINTS).find(key => CSS_BREAKPOINTS[key].id === i);
    let available = attribute.values[breakpoints];

    if (available) {
      if (available.start) {
        start = index ? available.start.value[index].value : available.start.value;
      }
      if (available.end) {
        end = index ? available.end.value[index].value : available.end.value;

        if (index) {
          unit = available.end.value[index].unit ? available.end.value[index].unit : "";
        } else {
          unit = available.end.unit ? available.end.unit : "";
        }
      }
    }

    if (typeof start === "string" || typeof end === "string")  {
      value = percents < 1 ? start : end;
    } else {
      value = Math.round((start + ((end - start) * percents)) * 1000) / 1000;
    }
  }

  if (/true|false/.test(value)) {
    value = JSON.parse(value);
  }

  return {
    value,
    unit,
    start,
    end,
  }
}

/**
 * ------------------------------------------------------------------------
 * Wrap element
 * ------------------------------------------------------------------------
 */

const wrapElement = (elements, wrapper, elementClass) => {
  if(elements instanceof Node) {
    elements = [elements];
  }

  if (wrapper instanceof Object) {
    let newElement = document.createElement("div");
    for (const element of elements) {
      newElement.appendChild(element);
    }

    wrapper.appendChild(newElement);
    elementClass && newElement.classList.add(elementClass);
  }
  else {
    for(let element of elements) {
      let newElement;
      let parentElement;
      let nextElement;

      nextElement = element.nextElementSibling;

      if(wrapper === "svg") {
        newElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        newElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
      }
      else {
        newElement = document.createElement(wrapper);
      }

      parentElement = element.parentElement;
      newElement.appendChild(element);
      elementClass && newElement.classList.add(elementClass);
      parentElement.insertBefore(newElement, nextElement);
    }
  }
}

/**
 * ------------------------------------------------------------------------
 * Transform string to camel case
 * ------------------------------------------------------------------------
 */

 String.prototype.toCamelCase = function () {
  return this.replace(/[-_]+/g, " ").replace(/ (.)/g, function ($1) { return $1.toUpperCase(); }).replace(/ /g, "");
};

/**
 * ------------------------------------------------------------------------
 * Check if SVG element (including <g> element) is in viewport
 * ------------------------------------------------------------------------
 */

const SVGIntersection = (bounds, method) => {
  let addition = 0;

  if (method === "intersecting") {
    addition = WINDOW.height/2;
  }

  return WINDOW.scroll.y + WINDOW.height + addition >= bounds.offsetTop &&
         WINDOW.scroll.y + WINDOW.height - addition <= bounds.offsetTop + bounds.height + WINDOW.height
}

/**
 * ------------------------------------------------------------------------
 * Calculate SVG elements bounds (Chrome only)
 * ------------------------------------------------------------------------
 */

const SVGBounds = (entry) => {
  if (WINDOW.idleCallback) {
    requestIdleCallback(() => {
      bounds(entry);
    });
  } else {
    requestAnimationFrame(() => {
      setTimeout(() => {
        bounds(entry);
      }, 0);
    });
  }

  function bounds(entry) {
    if (/svg/i.test(entry.target.nodeName)) {
      entry.target.TORUS = entry.target.TORUS || {};
      entry.target.TORUS.svg = entry.target.TORUS.svg || {};

      let rect = entry.boundingClientRect;
      let target = entry.target.TORUS.svg;
      let realWidth = entry.target.width.baseVal.value;
      let viewBoxWidth = entry.target.viewBox.baseVal.width ? entry.target.viewBox.baseVal.width : realWidth;

      target.rect = {
        offsetLeft: rect.left + WINDOW.scroll.x,
        offsetTop: rect.top + WINDOW.scroll.y,
      }

      for (const _this of target.children) {
        _this.set.bounds(_this.element.getBBox(), { rect: target.rect, ratio: realWidth / viewBoxWidth });
        _this.set.intersecting(SVGIntersection(_this.bounds, "intersecting"));

        if (_this.is.inviewElement) {
          _this.set.inview(SVGIntersection(_this.bounds, "inview"));
        }
      }
      target.calculated = true;
    }
  }
}

const inviewOriginalPosition = (_this) => {
  let scrolledTop = ((WINDOW.scroll.y + WINDOW.height - _this.bounds.offsetTopOriginal) / WINDOW.height) * 100;
  let scrolledBottom = ((WINDOW.scroll.y + WINDOW.height - _this.bounds.offsetBottom) / WINDOW.height) * 100;

  if (scrolledTop >= 0 && scrolledBottom < 100) {
    !_this.is.inview && _this.set.inview(true, true);
  } else {
    _this.is.inview && _this.set.inview(false, true);
  }
}

/**
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 * SETS
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * CSS
 * ------------------------------------------------------------------------
 */

const CSS_SET = {
  breakpoints: {},
  styles: new Set(),
};

for (const breakpoint of Object.keys(CSS_BREAKPOINTS)) {
  CSS_SET.breakpoints[breakpoint] = new Set();
}

/**
 * ------------------------------------------------------------------------
 * ELements sets
 * ------------------------------------------------------------------------
 */

const INVIEW_ELEMENTS = new Set();
const SCROLL_ELEMENTS = new Set();
const MOUSE_ELEMENTS = new Set();
const GROUP_ELEMENTS = new Set();
const CLASS_SCROLL_ELEMENTS = new Set();
const SVG_ELEMENTS = new Set();

/**
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 * OBSERVERS
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * Intersection observer
 * ------------------------------------------------------------------------
 */

const onIntersect = (entries, observer) => {
  for (const entry of entries) {
    const _this = entry.target.TORUS;

    if(_this) {
      /**
       * Main `data-tor` element
       */
      if (_this.Main) {
        /** Calculate the element bounds. Run only once */
        if (!_this.Main.bounds.calculated) {

          /** ScrollY is not undefined */
          if (WINDOW.scroll.y !== undefined) {
            _this.Main.set.bounds(entry.boundingClientRect);

            /** Element is intersecting */
            if (!_this.Main.is.svgChild) {
              if (entry.isIntersecting) {
                /** Check intersecting */
                _this.Main.set.intersecting(entry.isIntersecting);

                /** Check inview */
                // if (_this.Main.is.inviewElement) {
                //   _this.Main.run.inview();
                // }
              }
            }
          }
        }

        if (/ 50%/.test(observer.rootMargin)) {
          if (!_this.Main.is.svgChild) {
            _this.Main.set.intersecting(entry.isIntersecting);
          }
        }

        if (/ 0%/.test(observer.rootMargin)) {
          if (!_this.Main.has.originalPosition) {
            _this.Main.set.inview(entry.isIntersecting);
          } else {
            inviewOriginalPosition(_this.Main);
          }
        }
      }

      /**
       * parent `data-tor-parent` element
       */
      if (_this.Parent) {
        if (_this.Parent.is.inviewElement) {
          _this.Parent.run.inview();
        }
        if (/ 0%/.test(observer.rootMargin)) {
          _this.Parent.set.inview(entry.isIntersecting);
        }
      }

      /**
       * Chrome bug
       *
       * `intersectionObserver` doesn't work in Chrome for SVG elements, so we need to get the parent SVG rect
       * and use them on `getIntersectionList` function later
       */

      if (WINDOW.isUnsupportedSVG && _this.svg) {
        SVGBounds(entry);
      }
    }
  }
}

const INTERSECTION_OBSERVER = new IntersectionObserver(onIntersect, {root: null, rootMargin: "50%"});
const INVIEW_OBSERVER = new IntersectionObserver(onIntersect, {root: null, rootMargin: "0%"});

/**
 * ------------------------------------------------------------------------
 * Mutation
 * ------------------------------------------------------------------------
 */

let MUTATION_OBSERVER;

const MUTATION = () => {
  /** Append new <style> to <head> */
  document.head.appendChild(STYLE);

  const inits = ["Group", "Parent", "Main", "Slider"];

  const callback = () => {
    for (let i = 0; i < inits.length; i++) {
      TORUS[inits[i]].init();
    }

  };

  MUTATION_OBSERVER = new MutationObserver(callback);
  MUTATION_OBSERVER.observe(document, {childList: true, subtree: true});
};

/**
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 * LISTENER HANDLERS
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 */

function ON_RESIZE() {
  let tick = 0;

  if (!WINDOW.resizing) {
    requestAnimationFrame(raf);
    WINDOW.resizing = true;
  }

  function raf() {
    if (tick >= 50) {
      WINDOW.resizing = false;
      cancelAnimationFrame(raf);

      WINDOW.height = window.innerHeight || document.documentElement.clientHeight;
      WINDOW.width = window.innerWidth || document.documentElement.clientWidth;
      getCurrentResolution();

      if (TORUS.Main) {
        TORUS.Main.refresh();
      }

      if (TORUS.Loop) {
        TORUS.Loop.refresh();
      }

      if (TORUS.Slider) {
        TORUS.Slider.refresh();
      }
    }
    else {
      tick = tick + 1;
      requestAnimationFrame(raf);
    }
  }
}

/**
 * ------------------------------------------------------------------------
 * On Scroll
 * ------------------------------------------------------------------------
 */

const ON_SCROLL = () => {
  let scroll = WINDOW.scroll;

  scroll.tick = 0;
  getWindowScroll();

  if (!scroll.running) {
    requestAnimationFrame(ON_RAF);
    scroll.running = true;
  }
}

/**
 * ------------------------------------------------------------------------
 * On Element Scroll
 * ------------------------------------------------------------------------
 */

const ON_ELEMENT_SCROLL = (e) => {
  let scroll;

  // scroll.tick = 0;
  console.log(e.target.scrollTop);

  // if (!scroll.running) {
  //   requestAnimationFrame(ON_RAF);
  //   scroll.running = true;
  // }
}

/**
 * ------------------------------------------------------------------------
 * On Mouse
 * ------------------------------------------------------------------------
 */

const ON_MOUSE = (e) => {
  let mouse = WINDOW.mouse;

  mouse.tick = 0;
  mouse.x = e.clientX;
  mouse.y = e.clientY;

  if (!mouse.running) {
    requestAnimationFrame(ON_RAF);
    mouse.running = true;
  }
}

/**
 * ------------------------------------------------------------------------
 * On RequestAnimationFrame
 * ------------------------------------------------------------------------
 */

const ON_RAF = () => {
  let mouseActive = checkMouse(WINDOW.mouse).running;
  let scrollActive = checkScroll(WINDOW.scroll).running;

  if (mouseActive) {
    for (const _this of MOUSE_ELEMENTS) {
      _this.run.event("mouse");
    }
  }

  if (scrollActive) {
    for (const _this of SCROLL_ELEMENTS) {
      _this.run.event("scroll");
    }

    for (const _this of MOUSE_ELEMENTS) {
      if (_this.is.intersecting) {
        _this._setBounds(false);
      }
    }

    for (const _this of CLASS_SCROLL_ELEMENTS) {
      _this.run.classScroll();
    }

    if (WINDOW.isUnsupportedSVG) {
      for (const _this of SVG_ELEMENTS) {
        _this.set.intersecting(SVGIntersection(_this.bounds, "intersecting"));
        if (_this.is.inviewElement) {
          _this.set.inview(SVGIntersection(_this.bounds, "inview"));
        }
      }
    }

    for (const _this of INVIEW_ELEMENTS) {
      if (_this.is.intersecting && _this.is.inviewOffset) {
        let scrolled = ((WINDOW.scroll.y + WINDOW.height - _this.bounds.offsetTop) / WINDOW.height) * 100;
        if (scrolled >= _this.is.inviewOffset) {
          !_this.is.inview && _this.set.inview(true, true);
        } else {
          _this.is.inview && _this.set.inview(false, true);
        }
      } else if (_this.has.originalPosition) {
        inviewOriginalPosition(_this);
      } else {
        INVIEW_OBSERVER.observe(_this.element);
      }
    }
  }

  if (scrollActive || mouseActive) {
    requestAnimationFrame(ON_RAF);
  } else {
    cancelAnimationFrame(ON_RAF);
  }

}

function checkScroll(e) {
  if (e.tick >= 10) {
    e.running = false;
  } else {
    e.tick += 1;
  }
  return e;
}

function checkMouse(e) {
  if (e.tick >= 5) {
    e.running = false;
  } else {
    e.tick += 1;
  }
  return e;
}

/**
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 * On DOMContentLoaded
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 */

const ON_DOM = () => {
  WINDOW.DOMReady = true;

  const ro = new ResizeObserver(entries => {
    for (const entry of entries) {
      if (entry.target === document.documentElement) {
        if (WINDOW.idleCallback) {
          requestIdleCallback(() => TORUS.Main.refresh());
        } else {
          requestAnimationFrame(() => TORUS.Main.refresh());
        }
      }
    }
  });

  requestAnimationFrame(() => {
    document.body.classList.add("tor-loaded");
    WINDOW.isChrome && document.body.classList.add("tor-chrome");
    WINDOW.isSafari && document.body.classList.add("tor-safari");
    MUTATION_OBSERVER.disconnect();
    TORUS.BgImage.init();
    ro.observe(document.documentElement);
  });

  if (WINDOW.idleCallback) {
    requestIdleCallback(() => getWindowScroll());
  } else {
    requestAnimationFrame(() => getWindowScroll());
  }
}

/**
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 * LISTENERS
 * ------------------------------------------------------------------------------------------------------------------------------------------------
 */

window.addEventListener("scroll", ON_SCROLL, { passive: true });
window.addEventListener("pointermove", ON_MOUSE, { passive: true });
window.addEventListener("resize", ON_RESIZE);
window.addEventListener("DOMContentLoaded", ON_DOM);

export {
  TORUS,
  WINDOW,
  ATTRIBUTE_SEGMENTS,
  CSS_BREAKPOINTS,
  CSS_SET,
  CSS_TRIGGER_ALIAS,
  CSS_PROPERTIES,
  STYLE,
  INTERSECTION_OBSERVER,
  INVIEW_OBSERVER,
  INVIEW_ELEMENTS,
  SCROLL_ELEMENTS,
  MOUSE_ELEMENTS,
  GROUP_ELEMENTS,
  CLASS_SCROLL_ELEMENTS,
  SVG_ELEMENTS,
  ON_ELEMENT_SCROLL,
  MUTATION,
  optimizeAttribute,
  initClass,
  getIterableElement,
  getResolution,
  getValueData,
  getCSSVariable,
  getMaxSide,
  getPercents,
  getCounting,
  getCurrentResolution,
  getWindowScroll,
  parseValue,
  insertStylesheet,
  expandCluster,
  callFunction,
  isCurrentResolution,
  createPropertiesObject,
  getValuesForCurrentResolution,
  wrapElement,
  onIntersect,
  inviewOriginalPosition,
}