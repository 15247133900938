/**
 * ------------------------------------------------------------------------
 * Slider
 * (c) Torus Kit
 * ------------------------------------------------------------------------
 */

import TORUS from "./namespace";

import {
  getIterableElement,
  initClass,
  callFunction,
  optimizeAttribute,
  wrapElement,
  createPropertiesObject,
  getValuesForCurrentResolution,
} from "./util";

TORUS.Slider = class {
  constructor(element, options) {
    /** Main element */
    this.element = element;

    /** Element has been fully initialized */
    this.element.torusInitializedSlider = true;

    /** Optimize and replace original [data-tor] attribute */
    this.element.dataset.torSlider = optimizeAttribute(this.element.dataset.torSlider);

    /** Replace all ` ` spaces in (<value>) definition and split into an array */
    this.dataset = this.element.dataset.torSlider.replace(/\((.*?)\)+/g, match => match.replace(/ +/g, "░")).split(" ");

    /** Create store objects */
    this.attributes = {};
    this.bounds = {};
    this.items = {};
    this.navigation = {};

    /** Default Options */
    this.defaults = {
      count: 1,                   // Number of visible items per slide
      margin: 0,                  // Margin (space) between the items
      pullArea: 20,               // If the slider doesn't exceed this area when dragging, it reverses back
      stretchOnDrag: true,        // Stretch space between items on bounds when dragging
      stretchOnClick: false,      // Stretch space between items on bounds on click next/prev button
      addParent: false,          // Add parent `[data-tor-parent]` to `.tor-slider-item`
      slide: true,                // Enable slider sliding
      vertical: false,
      drag: true,
    }

    this.sliderStart = 0;
    this.activeSlide = 0;
    this.lastSlide = 0;
    this.lastDifferenceStart = 0;
    this.axis = "x";
    this.bounds.lastStart = -1;
    this.bounds.lastEnd = 0;

    /** Run Getter and setter function */
    this._getterSetter();
    /** Set default and merge user options */
    this._setOptions(options);
    /** Wrap and create inner slider elements */
    this._wrapItems();
    /** Get slider and slider items dimensions */
    this.get.bounds();
    /** Add event listeners */
    this._addListeners();
  }

  /**
   * ------------------------------------------------------------------------
   * Options
   * ------------------------------------------------------------------------
   */

  _setOptions(options) {
    /** Options defined in [data-tor-slider] attribute */
    createPropertiesObject(this, this.dataset, "slider");

    /** Merge user options with defaults */
    this.options = Object.assign(this.defaults, options);
    this.options.additional = {};

    /** If there are additional options defined in `[data-tor-slider]`, create them or replace the default ones */
    for (const attribute of Object.values(this.attributes.slider.idle)) {
      if (attribute.values) {
        let GV = getValuesForCurrentResolution(attribute, 1);
        let name = attribute.property.name.toCamelCase();
        this.options[name] = GV.value;

        if (Object.keys(attribute.options).length) {
          for (const [key, value] of Object.entries(attribute.options)) {
            this.options.additional[name] = this.options.additional[name] || {};
            this.options.additional[name][key] = value;
          }
        }
      }
    }

    /** If `stack(true)` override some default options */
    if (this.options.stack) {
      this.options.slide = false;
      this.options.drag = false;
      this.options.count = 1;
      this.options.margin = 0;
    }

    this.options.count  && this.element.style.setProperty("--tor-slider-items-count", this.options.count);
    (this.options.margin || this.options.margin === 0) && this.element.style.setProperty("--tor-slider-margin", `${this.options.margin}px`);

    if (this.options.additional) {
      let options = this.options.additional;

      (options.controls && options.controls.icons === "chevron")    && this.element.classList.add("tor-slider-controls-chevron");
      (options.controls && options.controls.theme === "dark")       && this.element.classList.add("tor-slider-controls-dark");
      (options.indicators && options.indicators.theme === "dark")   && this.element.classList.add("tor-slider-indicators-dark");
      (options.indicators && options.indicators.justify)            && this.element.style.setProperty("--tor-indicators-justify", options.indicators.justify);
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Wrap elements into corresponding wrappers
   * ------------------------------------------------------------------------
   */

  _wrapItems() {
    /** Select `.tor-slider-wrapper` wrapper element */
    this.slider = this.element.querySelector(".tor-slider-wrapper");

    /** If slider contains a wrapper */
    if (this.slider) {
      wrap.call(this, this.slider.children);
    } else {
      wrap.call(this, this.element.children);
      wrapElement(this.itemsElements, this.element, "tor-slider-wrapper");
      this.slider = this.element.querySelector(".tor-slider-wrapper");
    }

    /** Wrap each children into `.tor-slider-item` */
    function wrap([...children]) {
      children.map(item => {
        !/tor-slider/.test(item.classList) && wrapElement(item, "div", "tor-slider-item");
      });

      this.itemsElements = this.element.querySelectorAll(".tor-slider-item");
    }

    /** Add parent triggers if  */
    if (this.options.addParent) {
      for (const [i, item] of Object.entries(this.itemsElements)) {
        i === "0" && item.classList.add("active", "show");
        item.dataset.torParent = this.options.addParent.replace(/░/g, " ")
      }
    }

    /** If slider contains images */
    this.images = [...this.element.querySelectorAll("img")];

    /** If slider contains `data-tor` elements */
    this.torElements = [...this.element.querySelectorAll("[data-tor]")];

    this.itemsLength = this.itemsElements.length;
    this._createItemsObject();
  }

  /**
   * ------------------------------------------------------------------------
   * Create `this.items` object that stores data about each `.tor-slider-item`
   * ------------------------------------------------------------------------
   */

  _createItemsObject() {
    for (const [i, item] of Object.entries(this.itemsElements)) {
      this.items[i] = {};
      this.items[i].id = i;
      this.items[i].element = item;
      this.items[i].bounds = {};

      this.items[i].element.TORUS = this.items[i].element.TORUS || {};
      this.items[i].element.TORUS.sliderItem = {
        parent: this,
        id: i,
        calculated: false,
      };
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Defaults
   * ------------------------------------------------------------------------
   */

  _calculateDefaults() {
    this.maxStart = 0;
    this.maxEnd = -this.sliderSize + this.bounds.size - this.options.margin;
    this.maxSlides = this.itemsLength - this.options.count;

    if (this.options.vertical) {
      this.element.style.setProperty("--tor-slider-height", `${this.bounds.size}px`);
      this.axis = "y";
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Define getter and setter functions
   * ------------------------------------------------------------------------
   */

  _getterSetter() {
    /** Getter */
    this.get = {
      bounds: () => {
        this._getBounds();
      },
      images: url => new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => reject(img);

        img.src = url;
      }),
    }

    /** Setter */
    this.set = {
      bounds: (bounds, isItem) => {
        this._setBounds(bounds, isItem);
      },
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Get slider and child items bounds
   * ------------------------------------------------------------------------
   */

  _getBounds(params) {
    this.promises = [];
    this.sliderSize = 0;
    this.bounds.lastStart = -1;



    /** IO callback */
    const onIntersect = (entries) => {

      for (const entry of entries) {
        let _this = entry.target.TORUS;
        if (_this.Slider /*&& !this.bounds.calculated*/) {
           this.set.bounds(entry.boundingClientRect);
        }
        if (_this.sliderItem /*&& !_this.sliderItem.calculated*/) {
          this.set.bounds(entry.boundingClientRect, _this.sliderItem.id);
        }
      }

      IO.disconnect();

      Promise.all(this.promises).then(() => {
        this._promisesDone(params);
      });
    }

    /** IO init */
    const IO = new IntersectionObserver(onIntersect);

    if (this.images.length) {
      Promise.allSettled(this.images.map(item => item.src).map(this.get.images))
        .then(() => {
          runIO.call(this);
        });
    } else {
      runIO.call(this);
    }

    function runIO() {
      IO.observe(this.element);
      for (const item of Object.values(this.items)) {
        IO.observe(item.element);
      }
    }

  }

  /**
   * ------------------------------------------------------------------------
   * Set element bounds
   * ------------------------------------------------------------------------
   */

  _setBounds(bounds, id) {
    let promise = new Promise((resolve) => {
      let B;

      if (id) {
        B = this.items[id].bounds;
      } else {
        B = this.bounds;
      }

      B.calculated    = true;
      B.rect          = bounds;
      B.bottom        = Math.round(B.rect.bottom);
      B.width         = Math.round(B.rect.width);
      B.height        = Math.round(B.rect.height);

      if (id) {
        if (this.options.vertical) {
          B.size    = B.height + (Number(id) < this.itemsLength  ? this.options.margin : -this.options.margin);
        } else {
          B.size    = B.width + (Number(id) < this.itemsLength ? this.options.margin : -this.options.margin);
        }

        if (this.bounds.lastStart < 0) {
          this.bounds.lastStart = 0;
          B.start = 0;
          B.end = B.size;
        } else {
          this.bounds.lastStart += B.size;
          B.start = this.bounds.lastStart;
          B.end = B.start + B.size;
        }

        B.center  = B.start + B.size / 2;
        this.sliderSize = Math.abs(this.sliderStart) + B.end - (this.options.margin*2);

      } else {
        if (this.options.vertical) {
          B.size = B.height - this.options.margin;
        } else {
          B.size = B.width;
        }
      }

      resolve(B);
    });

    this.promises.push(promise);
  }

  /**
  * ------------------------------------------------------------------------
  * When all promises are settled
  * ------------------------------------------------------------------------
  */

  _promisesDone(params) {
    this.element.classList.add("tor-done");
    this._calculateDefaults();
    this._checkBounds();
    this._createNavigation();
    this._setClass("show");
    this._setClass("active");

    if (params && params.refreshing) {
      this.lastDifferenceStart = -this.items[this.activeSlide].bounds.start;
      this.sliderStart = -this.items[this.activeSlide].bounds.start;
      this._translate(this.sliderStart);
    }

    this._setIndicatorsClass();
  }

  /**
   * ------------------------------------------------------------------------
   * Set `.active` or `.show` class
   * ------------------------------------------------------------------------
   */

  _setClass(_class) {
    for (const item of Object.values(this.items)) {
      if (item.bounds.center + this.sliderStart > 0 && item.bounds.center + this.sliderStart < this.bounds.size) {
        item[_class] = true;
        item.element.classList.add(_class);
      } else {
        item[_class] = false;
        item.element.classList.remove(_class);
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Define the sliding direction
   * ------------------------------------------------------------------------
   */

  _slideTo(direction) {
    switch (direction) {
      case "next":
        if (this.activeSlide < this.maxSlides) {
          this.activeSlide++;
          setActiveAndTranslate.call(this);
        }
        break;

      case "prev":
        if (this.activeSlide > 0) {
          this.activeSlide--;
          setActiveAndTranslate.call(this);
        }
        break;

      default:
        if (Number(direction) < this.maxSlides) {
          this.activeSlide = Number(direction);
        } else {
          this.activeSlide = this.maxSlides;
        }
        setActiveAndTranslate.call(this);
        break;
    }

    function setActiveAndTranslate() {
      if (this.activeSlide !== this.lastSlide) {
        this.sliderStart = this.lastDifferenceStart = -this.items[this.activeSlide].bounds.start;
        this._translate(this.sliderStart);
        this._checkBounds();

        (!this.options.stack || !this.options.slide) && this.element.classList.add("tor-translating");
      }
    }

    if (this.options.stack) {
      this._setClass("active");
    }

    this._setIndicatorsClass();
    this.lastSlide = this.activeSlide;
  }

  /**
   * ------------------------------------------------------------------------
   * When slider transition (sliding) ends
   * ------------------------------------------------------------------------
   */

  _onTransitionEnd(e) {
    if (e.propertyName === "transform" && e.target === this.slider) {
      this._setClass("active");
      this.element.classList.remove("tor-translating");

      this._setIndicatorsClass();
    }
  }

  /**
   * ------------------------------------------------------------------------
   * On pointer down (mouse or touch down)
   * ------------------------------------------------------------------------
   */

  _onDown(e) {
    requestAnimationFrame(() => {
      if (e.button === 0) {
        this.isDown = true;
        this.startX = e.clientX;
        this.startY = e.clientY;
        this._checkBounds();
      }
    })
  }

  /**
   * ------------------------------------------------------------------------
   * On pointer move (mouse or touch moving)
   * ------------------------------------------------------------------------
   */

   _onMove(e) {
    requestAnimationFrame(() => {
      if (this.isDown) {
        this.element.classList.add("tor-dragging");

        if (this.options.vertical) {
          this.differenceStart = e.clientY - this.startY;
        } else {
          this.differenceStart = e.clientX - this.startX;
        }

        this.sliderStart = this.lastDifferenceStart + this.differenceStart;

        if (this.sliderStart > this.maxStart) {
          this.sliderStart = this.maxStart;
        }
        if (this.sliderStart < this.maxEnd) {
          this.sliderStart = this.maxEnd;
        }

        this._translate(this.sliderStart);
      }
    })
  }

  /**
   * ------------------------------------------------------------------------
   * On pointer up (mouse or touch release)
   * ------------------------------------------------------------------------
   */

  _onUp() {
    if (this.isDown) {
      this.element.classList.remove("tor-dragging");
      this.isDown = false;

      if (this.differenceStart < 0) {
        for (let i = 0; i < this.itemsLength; i++) {
          let draggedStart = this.items[i].bounds.start + this.sliderStart;
          if (draggedStart > -this.options.pullArea) {
            setActiveAndTranslate.call(this, i);
            break;
          }
        }
      }

      if (this.differenceStart > 0) {
        for (let i = this.itemsLength - 1; i >= 0; i--) {
          let draggedStart = this.items[i].bounds.start + this.sliderStart;

          if (draggedStart < this.options.pullArea) {
            setActiveAndTranslate.call(this, i);
            break;
          }
        }
      }

      // TODO: Free drag
      // this.sliderStart += this.differenceStart;
      // if (this.sliderStart > this.maxStart) {
      //   this.sliderStart = this.maxStart;
      // }
      // if (this.sliderStart < this.maxEnd) {
      //   this.sliderStart = this.maxEnd;
      // }
      // this._translate({left: this.sliderStart});

      this.lastDifferenceStart = this.sliderStart;
      this._setClass("active");

      function setActiveAndTranslate(i) {
        this.activeSlide = Number(this.items[i].id);
        this.sliderStart = -this.items[this.activeSlide].bounds.start;
        this._translate(this.sliderStart);
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * On drag start
   *
   * Disable browser dragging behavior when pointer starts to drag a slider
   * ------------------------------------------------------------------------
   */

    _onDragStart(e) {
      e.preventDefault();
      e.stopPropagation();
    }

  /**
   * ------------------------------------------------------------------------
   * Check if left or right bound has reached
   * ------------------------------------------------------------------------
   */

   _checkBounds() {
    this.reachedStart = this.sliderStart >= 0;
    this.reachedEnd = this.sliderStart <= this.maxEnd;
  }

  /**
   * ------------------------------------------------------------------------
   * Translate the `.tor-slider-wrapper` element
   * ------------------------------------------------------------------------
   */

  _translate(transform) {
    if (!this.options.stack) {
      let x = this.axis === "x" ? transform : 0;
      let y = this.axis === "y" ? transform : 0;

      this.slider.style.setProperty("transform", `translate3d(${x}px, ${y}px, 0px)`);
    }

    this._setClass("show");
  }

  /**
   * ------------------------------------------------------------------------
   * Add event listeners
   * ------------------------------------------------------------------------
   */

  _addListeners() {
    if (this.options.drag) {
      document.addEventListener("pointerup", this._onUp.bind(this));
      document.addEventListener("pointermove", this._onMove.bind(this), {passive: true});
      // document.addEventListener("mousemove", this._onMove.bind(this), {passive: true});
      // document.addEventListener("touchmove", this._onMove.bind(this), {passive: true});
      this.slider.addEventListener("pointerdown", this._onDown.bind(this));
    }

    this.slider.addEventListener("transitionend", this._onTransitionEnd.bind(this));
    this.slider.addEventListener("dragstart", this._onDragStart.bind(this));
  }

  /**
   * ------------------------------------------------------------------------
   * Set or remove `.active` class from indicator item elements
   * ------------------------------------------------------------------------
   */

  _setIndicatorsClass() {
    if (this.options.indicators) {
      if (this.navigation.indicatorsElements && this.navigation.indicatorsElements[this.activeSlide]) {
        [...Object.values(this.navigation.indicatorsElements)].map(item => item.classList.remove("active"));
        this.navigation.indicatorsElements[this.activeSlide].classList.add("active");
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Create Controls
   * ------------------------------------------------------------------------
   */

  _createNavigation() {
    if (this.options.controls) {
      /** At first, remove the controls element */
      this.navigation.controls && this.navigation.controls.remove();

      this.navigation.controls = document.createElement("nav");
      this.navigation.controls.classList.add("tor-slider-controls");
      this.element.appendChild(this.navigation.controls);

      for (const direction of ["prev", "next"]) {
        let control;
        control = document.createElement("div");
        control.addEventListener("click", this._slideTo.bind(this, direction));
        control.classList.add(`tor-slider-${direction}`);
        control.setAttribute("role", "button");
        control.setAttribute("aria-label", `Slider ${direction} button`);
        this.navigation.controls.appendChild(control);
      }
    } else {
      this.navigation.controls && this.navigation.controls.remove();
    }

    if (this.options.indicators) {
      /** At first, remove the indicator element */
      if (this.navigation.indicators) {
        [...Object.values(this.navigation.indicatorsElements)].map(item => item.remove());
        this.navigation.indicators.remove();
      }

      this.navigation.indicatorsElements = {};

      this.navigation.indicators = document.createElement("ul");
      this.navigation.indicators.classList.add("tor-slider-indicators");
      this.navigation.indicators.addEventListener("click", clicked.bind(this));
      this.element.appendChild(this.navigation.indicators);

      for (let i = 0; i < this.itemsLength / this.options.count; i++) {
        let indicator;
        indicator = document.createElement("li");
        indicator.setAttribute("role", "button");
        indicator.setAttribute("aria-label", `Show the number ${i * this.options.count} slide button`);
        indicator.setAttribute("data-tor-slide-to", i * this.options.count);
        this.navigation.indicators.appendChild(indicator);
        this.navigation.indicatorsElements[i * this.options.count] = indicator;
      }

      function clicked(e) {
        this._slideTo(Number(e.target.dataset.torSlideTo));
      }
    } else {
      this.navigation.indicators && this.navigation.indicators.remove();
    }
  }

  _refresh() {
    this._setOptions();
    this.get.bounds({refreshing: true});
  }

  /**
   * ------------------------------------------------------------------------
   * Static functions
   * ------------------------------------------------------------------------
   */

  /**
   * Slide to specific slide
   */

  static slideTo(elements, direction) {
    callFunction({
      elements: getIterableElement(elements),
      object: "Slider",
      fn: "_slideTo",
      argument: direction,
    });
  }

  /**
   * Refresh slider
   */

  static refresh(elements) {
    callFunction({
      elements: getIterableElement(elements || "[data-tor-slider]"),
      object: "Slider",
      fn: "_refresh",
    });
  }

  /**
   * Create external controls
   */

  static externalNavigation() {
    for (const navigation of document.querySelectorAll("[data-tor-slider-target]")) {

      if (!navigation.torExternalControl) {
        const targets = document.querySelectorAll(navigation.dataset.torSliderTarget);
        navigation.torExternalControl = true;

        navigation.addEventListener("click", (e) => {
          e.preventDefault();
          let control = e.target.dataset.torSlideTo;
          if (control) {
            [...targets].map(target => TORUS.Slider.slideTo(target, control));
          }
        });
      }
    }
  }

  /**
   * Init
   */

  static init(elements, options) {
    elements = getIterableElement(elements || "[data-tor-slider]");
    initClass({ name: "Slider", elements: elements, options: options });
    this.externalNavigation();
  }
}

export default TORUS.Slider;